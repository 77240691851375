import { defineStore } from 'pinia'
import WebApp from '@twa-dev/sdk'
// import * as Utils from './utilities';

export const useState = defineStore('state', {
    state: () => ({
        userInfoFromTelegram: null,
        accessToken: null,
        loadingComplete: false,
        registerComplete: false,
        checkingAgesComplete: false,
        dashboardComplete: false,
        userInfoFromServer: {
            userId: null,
            teleAccount: null,
            telePremium: null,
            teleAge: null,
            password: null,
            createdAt: null,
            totalPoints: null,
            invitedFriendsCount: null,
            invitationCode: null,
        },
        username: null,
        webappInitData: null,
        invitationCode: null
    }),
    actions: {
        initTelegramWebapp() {
            if (WebApp) {
                WebApp.ready();
                WebApp.expand();
                setTimeout(() => {
                    window.scrollBy(0, 1);
                    setTimeout(function () {
                        window.scrollBy(0, -1);
                    }, 300);
                }, 100);
                this.userInfoFromTelegram = WebApp.initDataUnsafe?.user || null;
                this.webappInitData = WebApp.initData;
                this.invitationCode = new URLSearchParams(window.location.search).get('tgWebAppStartParam');
                // if (this.accessToken) {
                //     Utils.setDefinKeyToLocalStorage(this.accessToken);
                // }
                WebApp.setHeaderColor('#141414');
                WebApp.disableVerticalSwipes();
            }
        },
        getUserInfoFromTelegram() {
            return this.userInfoFromTelegram;
        },
        getWebappInitData() {
            return this.webappInitData;
        },
        getAccessToken() {
            return this.accessToken;
        },
        setLoadingState(state) {
            this.loadingComplete = state;
        },
        getLoadingState() {
            return this.loadingComplete;
        },
        setRegisterState(state) {
            this.registerComplete = state;
        },
        getRegisterState() {
            return this.registerComplete;
        },
        setCheckingAgeState(state) {
            this.checkingAgesComplete = state;
        },
        getCheckingAgeState() {
            return this.checkingAgesComplete;
        },
        setDashboardState(state) {
            this.dashboardComplete = state;
        },
        getDashboardState() {
            return this.dashboardComplete;
        },
        setUserInfoFromServer(info) {
            this.userInfoFromServer = info;
        },
        getUserInfoFromServer() {
            return this.userInfoFromServer;
        },
        openLink(url) {
            if (!url) {
                return;
            }
            if (url.startsWith("https://t.me/")) {
                WebApp.openTelegramLink(url);
            } else {
                WebApp.openLink(url)
            }
        },
        setUsername(name) {
            this.username = name;
        },
        getUsername() {
            return this.username;
        }
    },
})
