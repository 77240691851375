<template>
    <div ref="referralFrame"
        class="absolute top-0 left-0 h-full w-full flex justify-center items-center transition-all duration-700 opacity-0 hidden">
        <img src="../assets/img/background-main.png" class="h-full w-full object-cover">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div class="w-[90%] h-[80%] flex justify-start items-start">
                <div v-if="yourReferalData"
                    class="w-full h-[95%] flex flex-col justify-start items-start overflow-y-scroll no-scrollbar">
                    <h1 v-if="findNextCommissionTier()"
                        class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mb-[3%]">
                        {{ findNextCommissionTier().subName }}: <span class="text-[#CDFB2D] uppercase">{{
                    findNextCommissionTier().name }}</span></h1>
                    <h1 v-else class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mb-[3%]">
                        {{ getLastCommissionTier().subName }}: <span class="text-[#CDFB2D] uppercase">{{
                    getLastCommissionTier().name }}</span></h1>
                    <div class="w-full aspect-[16/1] flex justify-center items-center">
                        <div class="w-[95%] h-full flex">
                            <div class="h-full w-[50%] flex justify-start items-center gap-[3%]">
                                <img src="../assets/img/points.webp" class="h-[90%] max-h-[25px]">
                                <h1 class="font-montserrat text-[#CDFB2D] text-[2vh] font-[400] leading-none">
                                    {{ yourReferalData.teleAccount }}
                                </h1>
                            </div>
                            <div class="h-full w-[50%] flex justify-end items-center">
                                <h1 class="font-montserrat text-[#CDFB2D] text-[2vh] font-[400] leading-none">
                                    {{ findNextCommissionTier().subName }} <span class="text-[#D9D9D9]">({{
                    yourReferalData.totalFriends }} referral)</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="w-full aspect-[16/1] flex justify-center items-center">
                        <div class="w-[93%] h-full flex justify-center items-center">
                            <div class="relative h-[40%] w-full bg-[#D9D9D9] rounded-3xl overflow-hidden">
                                <div :style="{ width: calcWidthBar() + '%' }"
                                    class="absolute top-0 left-0 h-full bg-[#CDFB2D]">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full aspect-[16/1] flex justify-center items-center mt-[2%]">
                        <div class="w-[90%] h-full flex justify-center items-center">
                            <h1 class="font-montserrat text-[#D9D9D9] text-[1.5vh] font-[400] leading-none text-center">
                                Referral friends to get higher badge and bonus % $vDEF from your friends point earning
                                (coming soon)
                            </h1>
                        </div>
                    </div>
                    <hr class="w-full border-[#d9d9d93f] mt-[5%] mb-[6%]">
                    <div class="w-full aspect-[2/1] min-h-[110px] flex overflow-y-hidden overflow-x-scroll no-scrollbar">
                        <div v-for="(commission, index) in commissionData" :key="commission"
                            :class="{ 'opacity-30': yourReferalData.totalFriends < commission.refRequired }"
                            class="h-full aspect-[1.7/2] flex flex-col justify-between items-center mr-[3%]">
                            <div
                                class="h-[15%] w-full border border-[#d9d9d93f] rounded-3xl flex justify-center items-center">
                                <h1
                                    class="font-montserrat text-[#CDFB2D] text-[1.5vh] font-[400] leading-none text-center uppercase">
                                    {{ commission.name }}
                                </h1>
                            </div>
                            <div class="relative w-[70%] aspect-square rounded-full border-4 border-[#CDFB2D] mt-[2%] mb-[2%]">
                                <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                    <img :src="require(`../assets/img/referral/${index + 1}.webp`)" class="h-[90%]">
                                </div>
                            </div>
                            <div class="h-[15%] w-full rounded-3xl flex justify-center gap-[3%] items-center">
                                <h1
                                    class="font-montserrat text-[#CDFB2D] text-[3vh] font-[700] leading-none text-center uppercase">
                                    {{ commission.commission }}%
                                </h1>
                                <h1
                                    class="font-montserrat text-[#CDFB2D] text-[2.5vh] font-[400] leading-none text-center uppercase">
                                    |
                                </h1>
                                <div class="h-full w-fit flex flex-col justify-center items-start">
                                    <h1
                                        class="font-montserrat text-[#CDFB2D] text-[1.3vh] font-[400] leading-none text-center">
                                        {{ commission.subName }}
                                    </h1>
                                    <h1
                                        class="font-montserrat text-[#D9D9D9] text-[1.3vh] font-[400] leading-none text-center">
                                        ({{ commission.refRequired }} ref)
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="w-full border-[#d9d9d93f] mt-[5%] mb-[6%]">
                    <div
                        class="w-full h-fit border border-[#d9d9d93f] rounded-2xl flex flex-col justify-center items-center pt-[5%] pb-[5%]">
                        <div class="w-[90%] aspect-[8/1] flex">
                            <div class="h-full w-[50%] flex flex-col justify-between items-center">
                                <h1
                                    class="font-montserrat text-[#D9D9D9] text-[1.8vh] font-[700] leading-none text-center">
                                    Total Friends
                                </h1>
                                <h1
                                    class="font-montserrat text-[#CDFB2D] text-[3vh] font-[700] leading-none text-center">
                                    {{ yourReferalData.totalFriends }}
                                </h1>
                            </div>
                            <div class="h-full w-[50%] flex flex-col justify-between items-center">
                                <h1
                                    class="font-montserrat text-[#D9D9D9] text-[1.8vh] font-[700] leading-none text-center">
                                    Total Earn
                                </h1>
                                <h1
                                    class="font-montserrat text-[#CDFB2D] text-[3vh] font-[700] leading-none text-center">
                                    {{ yourReferalData.totalEarn ?? 0 }}
                                </h1>
                            </div>
                        </div>
                        <div class="w-[90%] aspect-[8/1] flex mt-[3%] mb-[4%]">
                            <div class="h-full w-[50%] flex flex-col justify-between items-center">
                                <div @click="copyRefClick"
                                    class="w-[90%] h-full border border-dotted border-[#CDFB2D] rounded-md flex justify-center items-center">
                                    <img src="../assets/img/referral/copy.webp" class="h-5">
                                </div>
                            </div>
                            <div class="h-full w-[50%] flex flex-col justify-between items-center">
                                <div @click="shareClick" class="w-[90%] h-full bg-[#CDFB2D] rounded-md flex justify-center items-center">
                                    <img src="../assets/img/referral/share.webp" class="h-5">
                                </div>
                            </div>
                        </div>
                        <h1
                            class="font-montserrat text-[#D9D9D9] text-[1.5vh] font-[400] leading-none text-center mb-[1%]">
                            Invite friends to get % $vDEF from them
                        </h1>
                        <h1 class="font-montserrat text-[#D9D9D9] text-[1.5vh] font-[400] leading-none text-center">
                            Your invite link is ready!
                        </h1>
                        <hr class="w-[90%] border-[#d9d9d93f] mt-[6%] mb-[5%]">
                        <div v-for="userRef in yourReferalData.friends" :key="userRef"
                            class="w-[90%] aspect-[10/1] flex justify-between items-center">
                            <div class="h-full w-[70%] flex justify-start items-center gap-[3%]">
                                <img src="../assets/img/points.webp" class="w-[9%] max-h-[25px]">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.8vh] font-[400] leading-none">
                                    {{ userRef.teleAccount }}
                                </h1>
                            </div>
                            <h1 class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[500] leading-none">
                                {{ userRef.commission ?? 0 }}%
                            </h1>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useState } from '../assets/controller/state';
import * as definAPI from '../assets/controller/api';
import * as Utils from '../assets/controller/utilities';

export default {
    name: 'ReferralDefin',
    setup(props, { expose }) {
        const state = useState();
        const referralFrame = ref(null);
        const userTotalPoints = ref('12.543');
        const commissionData = ref([
            { refRequired: 5, name: 'Novice', subName: 'Tier 1', commission: 3 },
            { refRequired: 10, name: 'Apprentice', subName: 'Tier 2', commission: 5 },
            { refRequired: 50, name: 'Adept', subName: 'Tier 3', commission: 8 },
            { refRequired: 100, name: 'Expert', subName: 'Tier 4', commission: 10 },
            { refRequired: 300, name: 'Veteran', subName: 'Tier 5', commission: 13 },
            { refRequired: 500, name: 'Elite', subName: 'Tier 6', commission: 15 },
            { refRequired: 700, name: 'Champion', subName: 'Tier 7', commission: 18 },
            { refRequired: 800, name: 'Hero', subName: 'Tier 8', commission: 20 },
            { refRequired: 900, name: 'Legend', subName: 'Tier 9', commission: 23 },
            { refRequired: 1000, name: 'Grandmaster', subName: 'Tier 10', commission: 25 }
        ]);
        const yourReferalData = ref({
            teleAccount: '',
            totalFriends: 0,
            totalEarn: 0,
            friends: []
        });

        expose({
            referralFrame,
            showReferralFrame,
            hideReferralFrame
        });

        function showReferralFrame() {
            referralFrame.value.classList.remove('hidden');
            setTimeout(() => {
                referralFrame.value.classList.remove('opacity-0');
            }, 10);
        }

        function hideReferralFrame() {
            referralFrame.value.classList.add('opacity-0');
            setTimeout(() => {
                referralFrame.value.classList.add('hidden');
            }, 700);

        }

        function findCommissionTier() {
            const totalFriends = yourReferalData.value.totalFriends;
            for (let i = commissionData.value.length - 1; i >= 0; i--) {
                const tier = commissionData.value[i];
                if (totalFriends >= tier.refRequired) {
                    return tier;
                }
            }
            return null;
        }

        function findNextCommissionTier() {
            const totalFriends = yourReferalData.value.totalFriends;
            if (totalFriends < commissionData.value[0].refRequired) {
                return commissionData.value[0];
            }
            for (let i = commissionData.value.length - 1; i >= 0; i--) {
                const tier = commissionData.value[i];
                if (totalFriends >= tier.refRequired) {
                    if (i <= commissionData.value.length - 2) {
                        return commissionData.value[i + 1];
                    } else {
                        return null;
                    }
                }
            }
            return null;
        }

        function getLastCommissionTier() {
            if (commissionData.value.length === 0) {
                return null;
            }
            return commissionData.value[commissionData.value.length - 1];
        }

        function calcWidthBar() {
            const totalFriends = yourReferalData.value.totalFriends;
            const currentTier = findCommissionTier();
            const nextTier = findNextCommissionTier();

            if (!currentTier || !nextTier) {
                return 0;
            }

            const currentRefRequired = currentTier.refRequired;
            const nextRefRequired = nextTier.refRequired;

            if (nextRefRequired === currentRefRequired) {
                return 0;
            }

            return ((totalFriends - currentRefRequired) / (nextRefRequired - currentRefRequired)) * 100;
        }

        function dataEventListener() {
            document.addEventListener("dataEvent", async (event) => {
                const data = event.detail.message;
                if (data.callback === -1) {
                    const userId = state.getUserInfoFromServer().userId
                    const friends = await definAPI.userInvitationList(userId);
                    if (friends.success) {
                        yourReferalData.value.teleAccount = state.getUserInfoFromServer().teleAccount;
                        yourReferalData.value.totalEarn = 0;
                        yourReferalData.value.totalFriends = friends.data.length;
                        yourReferalData.value.friends = friends.data;
                    }
                }
            });
        }

        function copyRefClick() {
            Utils.copyTextToClipboard(`https://t.me/xdefin_bot?startapp=${state.getUserInfoFromServer().invitationCode}`);
        }

        function shareClick() {
            state.openLink(`https://t.me/share/url?url=https://t.me/xdefin_bot?startapp=${state.getUserInfoFromServer().invitationCode}&text=DEFIN - Contribute to Earn!`);
        }

        onMounted(() => {
            dataEventListener();
        });

        return {
            referralFrame,
            userTotalPoints,
            showReferralFrame,
            hideReferralFrame,
            commissionData,
            yourReferalData,
            findCommissionTier,
            findNextCommissionTier,
            getLastCommissionTier,
            calcWidthBar,
            copyRefClick,
            shareClick
        }
    }
}
</script>

<style scoped></style>
