<template>
    <div ref="loadingFrame"
        class="absolute top-0 left-0 h-full w-full flex justify-center items-center pointer-events-none">
        <div class="h-full w-full flex justify-center items-start">
            <div class="h-2/5 w-full flex justify-center items-end">
                <div ref="logo"
                    class="w-[22vh] h-fit flex flex-col justify-center items-center transition-all duration-700 translate-y-[60%]">
                    <img src="../assets/img/logo.webp" class="w-full box-shadow-logo">
                    <h1 class="font-azonix text-[#D9D9D9] text-[6.6vh]">DEFIN</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useState } from '../assets/controller/state';
import * as definAPI from '../assets/controller/api'
import * as Utils from '../assets/controller/utilities';

export default {
    name: 'LoadingDefin',

    setup() {
        const state = useState();
        const logo = ref(null);
        const loadingFrame = ref(null);

        async function startDefinBot() {
            const startBot = await definAPI.teleAuth(state.getWebappInitData(), state.getUserInfoFromTelegram()?.is_premium, state.invitationCode);
            if (startBot.success) {
                if (startBot?.data?.accessToken) {
                    Utils.setDefinKeyToLocalStorage(startBot.data.accessToken);
                }
                const userInfoFromServer = await definAPI.userInfo();
                state.setUserInfoFromServer(userInfoFromServer.data);
                if (!userInfoFromServer.data.teleAge && !userInfoFromServer.data.password) {
                    setTimeout(() => {
                        loadingFrame.value.classList.add('hidden');
                    }, 10);
                    state.setLoadingState(true);
                } else {
                    logo.value.classList.add('opacity-0');
                    setTimeout(() => {
                        setTimeout(() => {
                            loadingFrame.value.classList.add('hidden');
                        }, 10);
                        state.setLoadingState(true);
                    }, 700);
                }
            }
            Utils.dataDispatchEvent({ callback: -1 });
        }

        onMounted(() => {
            window.addEventListener('load', function () {
                state.initTelegramWebapp();
                setTimeout(() => {
                    const userInfoFromTelegram = state.getUserInfoFromTelegram();
                    if (userInfoFromTelegram) {
                        startDefinBot();
                    }
                }, 2000);
            });
        });

        return {
            logo,
            loadingFrame
        }
    }
}
</script>

<style scoped></style>